
import Agents from "~/graphql/Portfolio/Agents.gql";
import TenancySales from "~/graphql/Portfolio/TenancySales.gql";
import TenancyAgentValuations from "~/graphql/Portfolio/TenancyAgentValuations.gql";

import ViewSales from "~/pages/portfolio/components/Sales/ViewSales.vue";

import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { getLatestSalesFinances } from "~/helpers/portfolio/portfolio-sales-helpers";

export default {
  components: { ViewSales },

  inheritAttrs: false,

  props: {
    tenancyId: {
      type: String,
      required: true,
    },
  },

  apollo: {
    agents: {
      query: Agents,
      update(data) {
        return data.assetManagementAgents?.items;
      },
    },

    tenancySales: {
      query: TenancySales,
      variables() {
        return new InputBuilder().setId(this.tenancyId).build();
      },
      update(data) {
        return data.assetManagementTenancy.assetManagementSales?.items || [];
      },
    },

    tenancyValuations: {
      query: TenancyAgentValuations,
      variables() {
        return new InputBuilder().setId(this.tenancyId).build();
      },
      update(data) {
        return data.assetManagementTenancy;
      },
    },
  },

  computed: {
    agentsLoading() {
      return this.$apollo.queries.agents.loading;
    },

    salesLoading() {
      return this.$apollo.queries.tenancySales.loading;
    },

    valuationsLoading() {
      return this.$apollo.queries.tenancyValuations.loading;
    },

    salesFinances() {
      // For now, we only have one sale per tenancy
      return getLatestSalesFinances(this.tenancySales);
    },

    valuations() {
      return this.tenancyValuations?.assetManagementValuations?.items || [];
    },

    area() {
      return this.tenancyValuations ? { area: this.tenancyValuations.area, areaUnit: this.tenancyValuations.areaUnit } : null;
    },
  },

  methods: {
    updateSales() {
      this.$apollo.queries.tenancySales.refetch();
    },

    updateValuations() {
      this.$apollo.queries.tenancyValuations.refetch();
    },
  },
};
